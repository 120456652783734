import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { usePosts } from "@/composables/usePosts";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
    meta: { test: false, category: 0 },
    props: true,
  },
  {
    path: "/post/:id",
    name: "post",
    component: () => import("@/views/ThePost.vue"),
  },
  {
    path: "/wydarzenia",
    name: "events",
    component: () => import("@/views/TheEvents.vue"),
    props: true,
    meta: { category: "2,6" },
  },
  {
    path: "/akty",
    name: "deeds",
    component: () => import("@/views/TheDeeds.vue"),
    props: true,
    meta: { category: 4 },
  },
  {
    path: "/zebrania",
    name: "meetings",
    component: () => import("@/views/TheMeetings.vue"),
    props: true,
    meta: { category: 3 },
  },
  {
    path: "/kontakt",
    name: "contact",
    component: () => import("@/views/TheContact.vue"),
    props: true,
  },
  { path: "/:pathMatch(.*)*", component: () => import("@/views/NotFound.vue") },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach((to, from) => {
  const { clearPosts } = usePosts();

  if (from.name !== to.name) clearPosts();
});

export default router;
