import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createHead } from "@vueuse/head";
import AOS from "aos";

const app = createApp(App);
const head = createHead();

AOS.init();

require("./styles/main.sass");

// register global components
const globalComponents = require.context(
  "./components/global",
  true,
  /\.vue$/i
);
globalComponents.keys().map((key) => {
  if (key.includes("icons/")) {
    const componentConfig = globalComponents(key);
    // remove from filename './' from beginning, '.vue' at the end
    const componentName = `Icon${key
      .substr(0, key.length - 4)
      .substr(2)}`.replace("icons/", "");
    // register component
    app.component(componentName, componentConfig.default || componentConfig);
  } else {
    app.component(
      globalComponents(key).default.name ?? key.split("/").pop()?.split(".")[0],
      globalComponents(key).default
    );
  }
});

app.use(router).use(head).mount("#app");
