import { reactive, toRefs } from "vue";
import { IMenuItem } from "@/interfaces/IMenu";

const state: {
  menuItems: IMenuItem[];
  mobileBreakpoint: number;
  smallMenuBreakpoint: number;
  backgroundAlwaysVisible: boolean;
} = reactive({
  menuItems: [
    { name: "home", routerLinkName: "home" },
    { name: "akty prawne", routerLinkName: "deeds" },
    { name: "wydarzenia", routerLinkName: "events" },
    { name: "zebrania", routerLinkName: "meetings" },
    { name: "kontakt", routerLinkName: "contact" },
  ],
  mobileBreakpoint: 1024,
  smallMenuBreakpoint: 150,
  backgroundAlwaysVisible: false,
});

export const useMenu = () => {
  const setBackgroundVisibility = (visibile: boolean) => {
    state.backgroundAlwaysVisible = visibile;
  };

  return { setBackgroundVisibility, ...toRefs(state) };
};
