import { reactive, toRefs } from "vue";
import { useApi } from "@/composables/useApi";
import { useRoute } from "vue-router";

const state: {
  isLoading: boolean;
  posts: Array<any>;
  post: any;
  page: number;
  totalPages: number;
  totalPosts: number;
  noPosts: boolean;
  pagePhoto: string | null;
} = reactive({
  noPosts: false,
  isLoading: true,
  posts: [],
  post: null,
  page: 1,
  totalPages: 0,
  totalPosts: 0,
  pagePhoto: null,
});

export const usePosts = () => {
  const { useGet } = useApi();
  const router = useRoute();

  const fetchPost = async (id: number) => {
    state.isLoading = true;

    const postData = await useGet(`/wp/v2/posts/${id}`);
    state.post = postData.data;

    state.isLoading = false;
  };

  const fetchPosts = async (category?: number | string) => {
    state.isLoading = true;

    const page = router.query.page || 1;
    state.page = +page;

    let params = ``;
    if (category) {
      params += `&categories=${category}`;
    }
    params += "&per_page=10";

    const postsData = await useGet(`/wp/v2/posts?page=${page}${params}`);
    state.totalPosts = +postsData.headers["x-wp-total"];
    state.totalPages = +postsData.headers["x-wp-totalpages"];

    const posts = postsData.data;

    state.posts = posts;
    state.isLoading = false;

    if (state.pagePhoto) return;

    if (posts.length === 0) state.noPosts = true;

    if (+page === 1 && posts[0]) {
      state.pagePhoto = posts[0].acf.postMainPhoto.url;
    } else {
      let tmpParams = "";
      if (category) {
        tmpParams += `&categories=${category}`;
      }
      const tmpData = await useGet(
        `/wp/v2/posts?page=1&per_page=1${tmpParams}`
      );

      state.pagePhoto =
        tmpData.data[0] && tmpData.data[0].acf.postMainPhoto.url;
    }
  };

  const setPage = (page: number) => {
    state.page = page;
  };

  const clearPosts = () => {
    state.posts = [];
    state.post = null;
    state.noPosts = false;
    state.pagePhoto = null;
  };

  return { clearPosts, setPage, fetchPost, fetchPosts, ...toRefs(state) };
};
