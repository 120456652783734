import { EApiMethods } from "@/enums/api";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { camelizeKeys, decamelizeKeys } from "humps";

const api = axios;

api.defaults.baseURL = process.env.VUE_APP_CMS_API;
api.defaults.headers.common.Accept = "application/json";
api.defaults.headers.common["Content-Type"] = "application/json";
api.interceptors.response.use((response: AxiosResponse) => {
  if (
    response.data &&
    response.headers["content-type"] === "application/json"
  ) {
    response.data = camelizeKeys(response.data);
  }
  return response;
});
api.interceptors.request.use(
  async (config) => {
    const newConfig = { ...config };
    if (config.data) {
      newConfig.data = decamelizeKeys(config.data);
    }
    return newConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const useApi = (): {
  useGet: (
    url: string,
    params?: any,
    config?: AxiosRequestConfig
  ) => Promise<AxiosResponse<any>>;
  usePost: (
    url: string,
    params?: any,
    config?: AxiosRequestConfig
  ) => Promise<AxiosResponse<any>>;
  usePatch: (
    url: string,
    params?: any,
    config?: AxiosRequestConfig
  ) => Promise<AxiosResponse<any>>;
} => {
  const fetch = async (
    method: EApiMethods,
    url: string,
    params?: any,
    config?: AxiosRequestConfig
  ) => {
    try {
      switch (method) {
        case EApiMethods.POST: {
          return await api.post(url, params, config);
        }
        case EApiMethods.PATCH: {
          return await api.patch(url, params);
        }
        case EApiMethods.GET: {
          return await api.get(url, {
            ...params,
            ...config,
          });
        }
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const useGet = async (
    url: string,
    params?: any,
    config?: AxiosRequestConfig
  ) => {
    try {
      return fetch(EApiMethods.GET, url, params, config);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const usePost = async (
    url: string,
    params?: any,
    config?: AxiosRequestConfig
  ) => {
    try {
      return fetch(EApiMethods.POST, url, params, config);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const usePatch = async (url: string, params?: any) => {
    try {
      return fetch(EApiMethods.PATCH, url, params);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return {
    useGet,
    usePost,
    usePatch,
  };
};
